/* 
  Autor: Orangepix, Davide Mazzonetto
  1. General layout
  2. Footer
*/



/* 1. General */
section{
  padding: $sectionPadding;
}
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/open-sans/open-sans-v14-latin_cyrillic-300.eot);
  src: local("Open Sans Light"), local("OpenSans-Light"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-300.eot?#iefix) format("embedded-opentype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-300.woff2) format("woff2"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-300.woff) format("woff"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-300.ttf) format("truetype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-300.svg#OpenSans) format("svg")
}

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/open-sans/open-sans-v14-latin_cyrillic-regular.eot);
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-regular.eot?#iefix) format("embedded-opentype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-regular.woff2) format("woff2"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-regular.woff) format("woff"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-regular.ttf) format("truetype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-regular.svg#OpenSans) format("svg")
}

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/open-sans/open-sans-v14-latin_cyrillic-italic.eot);
  src: local("Open Sans Italic"), local("OpenSans-Italic"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-italic.eot?#iefix) format("embedded-opentype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-italic.woff2) format("woff2"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-italic.woff) format("woff"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-italic.ttf) format("truetype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-italic.svg#OpenSans) format("svg")
}

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/open-sans/open-sans-v14-latin_cyrillic-600.eot);
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-600.eot?#iefix) format("embedded-opentype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-600.woff2) format("woff2"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-600.woff) format("woff"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-600.ttf) format("truetype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-600.svg#OpenSans) format("svg")
}

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/open-sans/open-sans-v14-latin_cyrillic-700.eot);
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-700.eot?#iefix) format("embedded-opentype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-700.woff2) format("woff2"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-700.woff) format("woff"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-700.ttf) format("truetype"),
  url(../fonts/open-sans/open-sans-v14-latin_cyrillic-700.svg#OpenSans) format("svg")
}


/* 2. Footer */
footer{
  ul{
    li{
      a{
        color: #9f9f9f;
        text-decoration: none;
      }
    }
  }
  .copyright{
    .legal{
      color: #8c8c8c;
      a{
        color: #9f9f9f;
      }
    }
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', Verdana, Geneva, Tahoma, sans-serif;
  display: grid;
  place-items: center;
  min-height: 100vh;
  /*overflow: hidden;*/
  background: url('https://app.orangepix.it/repo/custom/login-background.jpg');
  background-size: cover;
}
