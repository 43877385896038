/* 
  Author: Orangepix
  Main scss file

  1. Variables
  2. Bootstrap override
  3. Imports
*/

/* Variables */
$primaryColor: #f38426;
$secondaryColor: #f38426;
$tertiaryColor: #f38426;
$sectionPadding: 85px 0;

/* Bootstrap override */
$primary: $primaryColor;
$secondary: $secondaryColor;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
$font-family-sans-serif: sans-serif;
$font-size-base: .9rem; 
$min-contrast-ratio: 2; /* The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7. See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast */
$border-radius: 0px;
$btn-border-radius: 0px;
$btn-border-radius-sm: 0px;
$btn-border-radius-lg: 0px;


/* Imports */
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/sweetalert2/dist/sweetalert2.css';
@import 'custom';